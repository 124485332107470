/* TODO have FED review and update accordingly */

.header,
.main-content, .main-content .inner,
.header-section.header-section-left {
    position: initial;
}

.header {
    position: static;
    width: initial;
    top: initial;
    left: initial;
    transition: initial;
    background-color: #003150;
    padding-top: 32px;
    padding-bottom: 8px;

    .inner {
        position: initial;
        z-index: initial;

        &:before {
            content: none;
        }

        .container-fluid:before {
            content: none;
        }

        .header-section-left {
            position: initial;
            max-width: initial;
            padding: initial;
            top: initial;
            left: initial;
            z-index: initial;
            transition: none;

            .brand {
                width: initial;
            }
        }
    }
}

.header.brand-secondary {
    padding-top: 0;
    background-color: #fff;
}

.header.introanim .brand {
    transition: none;
}

a {
    transition: none;
}

.tabs-section, .brand-logo.brand-logo {
    display: block;
    opacity: 1;
    visibility: visible;
}

.header-section-right,
.header-section-full,
.card-box,
button.readmore__trigger,
.tabs-list,
.buttons,
.filtertoggles,
.teaser,
.footer-nav-container,
.brand .brand-logo-secondary,
.hero-section .link-block-more,
.accordion__icon,
.narrative__button,
.footer,
tabs-triggers,
tabs-title,
.print--hide {
    display: none;
}

.hero-section {
    margin-top: 0;
}

.hero-container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.hero .hero-image {
    margin-left: 0;
    margin-right: 0;
}

@media print {
    * {
        -webkit-print-color-adjust: exact;
    }
}

.tabs-section {
    height: auto;
}

.hero-columns__column--pushdown, .hero-columns__column--secondary {
    margin: 0 auto;
}

.bg-color-primary.hero-flex-content {
    padding-top: 0;
}

.readmore__content, .accordion__inner {
    height: auto !important;
}

section.section {
    padding-top: 0;
    margin-top: 0;

    &:first-of-type {
        margin-top: 64px;
    }
}

.footer-inner {
    padding: 40px 0;
}

.page-limit-narrow {
    padding-left: 0;
    padding-right: 0;
}

.hero-columns {
    padding-bottom: 0;
}

.accordion__bar {
    opacity: 1;
    border-color: #f8f8f8;
    background-color: #f8f8f8;
    color: #003150;
}

.narrative__text {
    width: auto !important;
    padding-right: 0;
}

.section--bg-faded {
    background-color: #FFF;
}

.printonly {
    display: block;
}

.header-section-left {
    position: static;

    .scrolling-down & {
        top: 0;
    }
}

.columns-inline,
.authors-module,
.tile-inner {
    page-break-inside: avoid;
}

.authors-module {
    &:after {
        content: ' ';
        clear: both;
        display: table;
    }
}

.simple-tabs {
    .simple-tab {
        display: block !important;
    }
}

.scrolling-up {
    .header {
        background-color: initial;
        box-shadow: initial;
    }

    .brand {
        .brand-logo {
            display: block;
        }

        .brand-logo-secondary {
            display: none;
        }
    }
}

.page__offices {
    .tile {
        min-height: initial;
        padding: 2px;
    }

    .tile-inner .tile-row {
        padding: 0;
    }
}

.page-sections-neg {
    margin-top: 0;
}

.authors-list {
    position: relative;
    display: block;

    .authors-link {
        width: 34%;
        float: left;
        display: block;
    }

    .authors-meta {
        width: calc(66% - 48px);
        float: left;
    }
}
